export const languages = [
  "Afrikaans",
  "Arabic",
  "Bengali",
  "Bulgarian",
  "Catalan",
  "Cantonese",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "Lithuanian",
  "Malay",
  "Malayalam",
  "Panjabi",
  "Tamil",
  "English",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Italian",
  "Japanese",
  "Javanese",
  "Korean",
  "Norwegian",
  "Polish",
  "Portuguese",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovene",
  "Spanish",
  "Swedish",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
  "Welsh",
  "Sign language",
  "Algerian",
  "Aramaic",
  "Armenian",
  "Berber",
  "Burmese",
  "Bosnian",
  "Brazilian",
  "Bulgarian",
  "Cypriot",
  "Corsica",
  "Creole",
  "Scottish",
  "Egyptian",
  "Esperanto",
  "Estonian",
  "Finn",
  "Flemish",
  "Georgian",
  "Hawaiian",
  "Inuit",
  "Irish",
  "Icelandic",
  "Latin",
  "Mandarin",
  "Nepalese",
  "Sanskrit",
  "Tagalog",
  "Tahitian",
  "Tibetan",
  "Gypsy",
  "Wu",
];
