import * as React from 'react';
import { useFormik } from 'formik';
import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import { countries } from 'bloggers-backend/lib/bloggers/dicts/countries';
import CircularProgress from '@mui/material/CircularProgress';

import { CreateBloggerEvent, CreateBloggerResult} from 'bloggers-backend/lib/bloggers/domain';
import {ValidateOnCreateEvent, ValidateOnCreateResult } from 'bloggers-backend/lib/bloggers/domain';
import { AppContext } from '../AppContext';


export default function CreateBloggerPage() {
  const {notificationsService, client, user} = React.useContext(AppContext)
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      youtube: '',
      country: null,
      instagram: '',
      tikTok: '',
      facebook: '',
      email: '',
      addEmail: '',
      youtubeChannelId: ''
    },
    validate: async (values) => {
      setLoading(true);
      const result = await client<ValidateOnCreateEvent, ValidateOnCreateResult>({
        event: 'validateOnCreate',
        data: values
      });
      setLoading(false);
      if (!result.success) {
        return result.errors
      }
    },
    onSubmit: async (values) => {
        setLoading(true);
        const result = await client<CreateBloggerEvent, CreateBloggerResult>({
          event: 'createBlogger',
          data: values
        });

        if (result.success) {
          notificationsService.notify({
            severity: 'success',
            message: 'Blogger created'
          });
          if (user.role === "creator") {
            navigate(`/bloggers/`);
          } else {
            navigate(`/bloggers/${result.value}`);
          }
          
        } else {
          if (result.reason === 'validation') {
            formik.setErrors(result.errors)

            if (result.errors._) {
              notificationsService.notify({
                severity: 'error',
                message: result.errors._.join(', ')
              });
            }
          } else {
            notificationsService.notify({
              severity: 'error',
              message: result.reason
            })
          }
        }
        setLoading(false);
    }
  });

  const header = 'Create a new blogger:'

  const buttonOrLoading = 
      loading
      ? <CircularProgress />
      : (<Button
          variant="contained"
          type="submit">Submit
      </Button>)

  return (
    <form onSubmit={formik.handleSubmit}>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            '& > :not(style)': { m: 1 },
            maxWidth: '400px',
        }}>
            <h2>{header}</h2>
            <TextField
                id="name"
                name="name"
                label="Name"
                variant="outlined"
                required
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                onChange={formik.handleChange}
                value={formik.values.name}
            />
            <TextField
                id="youtube"
                name="youtube"
                label="youtube"
                variant="outlined"
                required={user.role === "creator"}
                error={!!formik.errors.youtube}
                helperText={formik.errors.youtube}
                onChange={formik.handleChange}
                value={formik.values.youtube}
            />
            <TextField
                id="youtubeChannelId"
                name="youtubeChannelId"
                label="youtubeChannelId"
                variant="outlined"
                error={!!formik.errors.youtubeChannelId}
                helperText={formik.errors.youtubeChannelId}
                onChange={formik.handleChange}
                value={formik.values.youtubeChannelId}
            />
            <Autocomplete
              id="country"
              onChange={(_, value) => formik.setFieldValue('country', value)}
              options={countries}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!formik.errors.country}
                  helperText={formik.errors.country}
                  id="country"
                  label="country"
                />}
            />
            <TextField
                id="instagram"
                name="instagram"
                label="instagram"
                variant="outlined"
                error={!!formik.errors.instagram}
                helperText={formik.errors.instagram}
                onChange={formik.handleChange}
                value={formik.values.instagram}
            />

            <TextField
                id="tikTok"
                name="tikTok"
                label="tikTok"
                variant="outlined"
                error={!!formik.errors.tikTok}
                helperText={formik.errors.tikTok}
                onChange={formik.handleChange}
                value={formik.values.tikTok}
            />

            <TextField
                id="facebook"
                name="facebook"
                label="facebook"
                variant="outlined"
                error={!!formik.errors.facebook}
                helperText={formik.errors.facebook}
                onChange={formik.handleChange}
                value={formik.values.facebook}
            />

            <TextField
                id="email"
                name="email"
                label="email"
                variant="outlined"
                onChange={formik.handleChange}
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                value={formik.values.email}
            />

            <TextField
                id="addEmail"
                name="addEmail"
                label="additional email"
                variant="outlined"
                onChange={formik.handleChange}
                error={!!formik.errors.addEmail}
                helperText={formik.errors.addEmail}
                value={formik.values.addEmail}
            />

            {buttonOrLoading}
        </Box>
    </form>
  )
};