export interface Success<T> {
    success: true;
    value: T;
}

interface FailtureErrors {
    [name: string]: string[]
}

export interface Failure {
    success: false;
    reason: string;
    errors: {[name: string]: string[]}
}

export type Result<T> = Success<T> | Failure;

export function success<T>(value: T): Success<T> {
  return {success: true, value};
}

type Reason = "validation" | "not allowed" | "network" | string;

export function failure(reason: Reason, errors: FailtureErrors = {}): Failure {
  return {success: false, errors, reason};
}
