import * as React from 'react';

import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { GetReportEvent, GetReportResult, Report } from 'bloggers-backend/lib/bloggers/domain';
import { AppContext } from '../AppContext';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';

export function ReportPage() {
  const {notificationsService, client} = React.useContext(AppContext);

  const [report, setReport] = React.useState<Report>([]);
  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([
    dayjs().subtract(1, 'month'), dayjs().add(1, 'day')
  ]);

  const onClick = async () => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) {
      return;
    }
    const result = await client<GetReportEvent, GetReportResult>({
      event: 'getReport',
      data: {
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      }
    })
  
    if (result.success) {
      setReport(result.value);
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });
  
      
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateRangePicker 
          value={dateRange}
          onChange={(newValue) => setDateRange(newValue)}
          format="DD/MM/YYYY"
          localeText={{ start: 'start date', end: 'end date' }} 
          
        />
      </LocalizationProvider>
      <Button onClick={onClick}>get report</Button>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', width: '30%'}}>manager name</TableCell>
            <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="right">comment count</TableCell>
            <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="right">no answer</TableCell>
            <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="right">pending</TableCell>
            <TableCell style={{ fontWeight: 'bold', width: '15%' }} align="right">affiliate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          report.map(row => {
            return (
              <TableRow key={row.userName}>
                <TableCell>{row.userName}</TableCell>
                <TableCell align="right">{row.totalComments}</TableCell>
                <TableCell align="right">{row.noAnswerCount}</TableCell>
                <TableCell align="right">{row.pendingCount}</TableCell>
                <TableCell align="right">{row.affiliateCount}</TableCell>
              </TableRow>
            );
          })
        }
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}