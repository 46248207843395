export function convertUtcToLocalTime(utcTime: string) {
  if (!utcTime) {
    return "";
  }
  const utcDate = new Date(utcTime);
  return utcDate.toLocaleString('ru-RU').replace(',', ''); 
};

export function formatISODate(iso: string) {
  const [date, timeWithMs] = iso.split('T')
  const [time] = timeWithMs.split('.');

  return `${date} ${time}`
};
