import { GetAllSearchTaskEvent, GetAllSearchTaskResult } from 'bloggers-backend/lib/youtube/domain/events';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Grid, { QueryOptions } from './Grid';
import { AppContext } from '../AppContext';
import { convertUtcToLocalTime } from '../Utils';


const columns = [
  { field: 'query', width: 150, filterable: true, sortable: true },
  { field: 'createdAt', headerName: 'created at', width: 160, filterable: true, sortable: true, valueGetter: ({ row }: any) => convertUtcToLocalTime(row?.createdAt)},
  { field: 'status', headerName: 'status', width: 150, filterable: true, sortable: true },
  { field: 'completed', headerName: 'completed', width: 150, filterable: true, sortable: true, type: 'boolean'},
  { field: 'manager.name', headerName: 'manager', width: 150, filterable: true, sortable: true, valueGetter: ({ row }: any) => row?.manager?.name},
];


export default function SearchTasksPage() {
  const {notificationsService, client} = React.useContext(AppContext)
  const navigate = useNavigate();

  const fetchData = async (options: QueryOptions) => {
    const result = await client<GetAllSearchTaskEvent, GetAllSearchTaskResult>({
      event: 'getAllSearchTasks',
      data: options as any
    })

    if (result.success) {
      return result.value
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });

      return {data: [], pageInfo: {}}
    }
  };

  return <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Box sx={{marginBottom: 1}}>
      <Button
        variant="contained"
        onClick={() => navigate('/search-tasks/new')}
      >
        Create new search task
      </Button>
    </Box>
    <Box sx={{flexGrow: 1}}>
      <Grid
        columns={columns}
        cacheName={'search-tasks'}
        fetchData={fetchData}
        onRowClick={(id) => navigate(`/search-tasks/${id}`)}
      />
    </Box>
  </Box>
}
