import * as React from 'react';
import { useFormik } from 'formik';
import { Alert, Button, Card, CardContent, IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import Tooltip from '@mui/material/Tooltip';

import { countries } from 'bloggers-backend/lib/bloggers/dicts/countries';
import { hosts } from 'bloggers-backend/lib/bloggers/dicts/hosts';
import { statuses } from 'bloggers-backend/lib/bloggers/dicts/statuses';
import { matters } from 'bloggers-backend/lib/bloggers/dicts/matters';
import { themes } from 'bloggers-backend/lib/bloggers/dicts/themes';

import {stringify} from 'yaml';

import CircularProgress from '@mui/material/CircularProgress';

import {
  BloggerQueryResult,
  GetBloggerEvent,
  GetBloggerResult,
  UpdateBloggerEvent,
  UpdateBloggerResult,
  DeleteBloggerEvent,
  DeleteBloggerResult
} from 'bloggers-backend/lib/bloggers/domain';
import { ValidateOnUpdateEvent, ValidateOnUpdateResult } from 'bloggers-backend/lib/bloggers/domain';
import {UserLinkInput} from '../components/UserLinkInput';
import { PickDateDialog } from '../components/PickDateDialog';
import { 
  CreateNotificationEvent,
  CreateNotificationEventResult, 
  DeleteNotificationEvent, 
  DeleteNotificationEventResult 
} from 'bloggers-backend/lib/notifications/domain/events';
import { DeleteFlow } from '../components/DeleteFlow';
import { AppContext } from '../AppContext';
import { convertUtcToLocalTime, formatISODate } from '../Utils';
import { Wrapper } from '../components/forms/Wrapper';
import { NewEmailDialog } from '../components/NewEmailDialog';

interface User {
  role: string
  id: string
  name: string
}

function canEditComment(user: User, comment: {user: {id: string}}) {
  return user.role !== "admin" && comment.user.id !== user.id
}


export default function EditBloggerPage() {
  const {notificationsService, client, user} = React.useContext(AppContext)
  const {id} = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [pickDateDialogOpen, setPickDateDialogOpen] = React.useState(false);
  const [notification, setNotification] = React.useState<{commentId: string, text: string, bloggerId: string, bloggerName: string}>();

  const [commentText, setCommentText] = React.useState('');

  async function reloadForm(id: string) {
    setLoading(true);
    const result = await client<GetBloggerEvent, GetBloggerResult>({
      event: 'getBlogger',
      data: {id}
    });

    if (result.success) {
      formik.resetForm({
        values: {...initialValues, ...result.value}
      })
    } else {
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      })
    }

    setLoading(false)
  }


  interface BloggerWithErrors extends BloggerQueryResult {
    _?: string
  }

  const initialValues: BloggerWithErrors = {
    id: '',
    status: '',
    hosts: [] as string[],
    createTime: '',
    name: '',
    youtube: '',
    country: '',
    instagram: '',
    tikTok: '',
    youtubeChannelId: '',
    facebook: '',
    email: '',
    addEmail: '',
    comments: [],
    channelTheme: '',
    matterOfContact: '',
    manager: null,
    lastComment: null,
    createdBy: {id: ''},
    history: []
  }

  const formik = useFormik({
    initialValues,
    validate: async (values) => {
      setLoading(true);
      const result = await client<ValidateOnUpdateEvent, ValidateOnUpdateResult>({
        event: 'validateOnUpdate',
        data: values
      });
      setLoading(false);
      if (!result.success) {
        return result.errors
      }
    },
    onSubmit: async (values) => {
        setLoading(true);
        const result = await client<UpdateBloggerEvent, UpdateBloggerResult>({
          event: 'updateBlogger',
          data: values
        });

        if (result.success) {
          notificationsService.notify({
            severity: 'success',
            message: 'Blogger updated'
          });

          await reloadForm(values.id);
        } else {
          if (result.reason === 'validation') {
            formik.setErrors(result.errors)

            if (result.errors._) {
              notificationsService.notify({
                severity: 'error',
                message: result.errors._.join(', ')
              });
            }
          } else {
            notificationsService.notify({
              severity: 'error',
              message: result.reason
            })
          }
        }
        setLoading(false);
    }
  });


  React.useEffect(() => {
    if (!id || loading) {
      return;
    }


    reloadForm(id);
  }, [id]);


  async function createNotification(isoTime: string) {
    setPickDateDialogOpen(false);
    setLoading(true);
  
    if (!id || !notification) {
      throw new Error('Can not createNotification with no id');
    }
    const result = await client<CreateNotificationEvent, CreateNotificationEventResult>({
      event: 'createNotification',
      data: {
        ...notification,
        isoTime,
        complete: false
      }
    });

    setLoading(false);

    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: 'Notification created'
      });

      await reloadForm(id);
    } else {
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      });
    }
  }

  async function deleteNotification(id: string) {
    const result = await client<DeleteNotificationEvent, DeleteNotificationEventResult>({
      event: 'deleteNotification',
      data: {id}
    });

    setLoading(false);

    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: 'Notification deleted'
      });
      await reloadForm(formik.values.id); 
    } else {
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      });
    }
  }


  async function deleteBlogger() {
    setLoading(true);
  
    if (!id) {
      throw new Error('Can not delete blogger with no id');
    }
    const result = await client<DeleteBloggerEvent, DeleteBloggerResult>({
      event: 'deleteBlogger',
      data: {id}
    });

    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: 'Blogger deleted'
      });
      navigate(`/bloggers/`);
    } else {
      setLoading(false);
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      });
    }
  }

  const header = `Edit blogger: ${formik.values.name}`

  const buttonOrLoading = 
      loading
      ? <CircularProgress />
      : (<Button
          variant="contained"
          type="submit">Submit
      </Button>)


  const AddCommentOrLoading = 
    loading
    ? <CircularProgress />
    : (
      <Button
        disabled={commentText === ''}
        onClick={() => {
          const newComments = formik.values.comments.concat([{
            text: commentText,
            date: new Date().toISOString(),
            user,
            notifications: []
          }])

          formik.setFieldValue('comments', newComments)
          setCommentText("");
          formik.handleSubmit()
        }}
        variant="contained"
        type="button">Add comment
      </Button>
    )

  return (
    <Box sx={{display: 'flex', flexDirection: 'row', height: '100%' }}>
      <Box sx={{width: '400px'}}>
        <form onSubmit={formik.handleSubmit}>
            <Wrapper>
                <h2>{header}</h2>
                <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    required
                    error={!!formik.errors.name}
                    helperText={formik.errors.name}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />

                <Autocomplete
                  id="country"
                  onChange={(_, value) => formik.setFieldValue('country', value)}
                  options={countries}
                  value={formik.values.country}
                  renderInput={(params) =>
                    <TextField {...params}
                      error={!!formik.errors.country}
                      helperText={formik.errors.country ? "country is required" : ""}
                      id="country"
                      label="country"
                    />}
                />
            
                <Autocomplete
                  id="channelTheme"
                  onChange={(_, value) => formik.setFieldValue('channelTheme', value)}
                  options={themes}
                  value={formik.values.channelTheme}
                  renderInput={(params) =>
                    <TextField {...params}
                      error={!!formik.errors.channelTheme}
                      helperText={formik.errors.channelTheme || ""}
                      id="channelTheme"
                      label="channel theme"
                    />}
                />

                <Autocomplete
                  id="status"
                  onChange={(_, value) => formik.setFieldValue('status', value)}
                  options={statuses}
                  value={formik.values.status}
                  renderInput={(params) =>
                    <TextField {...params}
                      error={!!formik.errors.status}
                      helperText={formik.errors.status || ""}
                      id="status"
                      label="status"
                    />}
                />

                <Autocomplete
                  id="matterOfContact"
                  onChange={(_, value) => formik.setFieldValue('matterOfContact', value)}
                  options={matters}
                  value={formik.values.matterOfContact}
                  renderInput={(params) =>
                    <TextField {...params}
                      error={!!formik.errors.matterOfContact}
                      helperText={formik.errors.matterOfContact || ""}
                      id="matterOfContact"
                      label="matter of contact"
                    />}
                />

                <Autocomplete
                  multiple
                  id="hosts"
                  options={hosts}
                  value={formik.values.hosts}
                  onChange={(_, value) => formik.setFieldValue('hosts', value)}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} label="hosts" placeholder="hosts" />
                  )}
                />

                {
                  formik.errors._
                    ? (
                      <Alert severity='error'>
                        {formik.errors._}
                      </Alert>
                    )
                    : <></>
                }
          
                <TextField
                    id="youtube"
                    name="youtube"
                    label="youtube"
                    variant="outlined"
                    error={!!formik.errors.youtube}
                    helperText={formik.errors.youtube}
                    onChange={formik.handleChange}
                    value={formik.values.youtube}
                />

                <TextField
                    id="youtubeChannelId"
                    name="youtubeChannelId"
                    label="youtubeChannelId"
                    variant="outlined"
                    error={!!formik.errors.youtubeChannelId}
                    helperText={formik.errors.youtubeChannelId}
                    onChange={formik.handleChange}
                    value={formik.values.youtubeChannelId}
                />

                <TextField
                    id="instagram"
                    name="instagram"
                    label="instagram"
                    variant="outlined"
                    error={!!formik.errors.instagram}
                    helperText={formik.errors.instagram}
                    onChange={formik.handleChange}
                    value={formik.values.instagram || ""}
                />

                <TextField
                    id="tikTok"
                    name="tikTok"
                    label="tikTok"
                    variant="outlined"
                    error={!!formik.errors.tikTok}
                    helperText={formik.errors.tikTok}
                    onChange={formik.handleChange}
                    value={formik.values.tikTok || ""}
                />

                <TextField
                    id="facebook"
                    name="facebook"
                    label="facebook"
                    variant="outlined"
                    error={!!formik.errors.facebook}
                    helperText={formik.errors.facebook}
                    onChange={formik.handleChange}
                    value={formik.values.facebook || ""}
                />

                <TextField
                    id="email"
                    name="email"
                    label="email"
                    variant="outlined"
                    onChange={formik.handleChange}
                    error={!!formik.errors.email}
                    helperText={formik.errors.email}
                    value={formik.values.email || ""}
                />

                <TextField
                    id="addEmail"
                    name="addEmail"
                    label="additional email"
                    variant="outlined"
                    onChange={formik.handleChange}
                    error={!!formik.errors.addEmail}
                    helperText={formik.errors.addEmail}
                    value={formik.values.addEmail || ""}
                />

                <UserLinkInput client={client}
                  id="manager"
                  name="manager"
                  label="manager"
                  variant="outlined"
                  required
                  error={!!formik.errors.manager}
                  helperText={formik.errors.manager}
                  onChoose={(manager) => {
                    formik.setFieldValue('manager', manager)
                  }}
                  value={formik.values.manager}
                />
                {buttonOrLoading}

                <PickDateDialog
                  isOpen={pickDateDialogOpen}
                  userRejects={() => setPickDateDialogOpen(false)}
                  userAccepts={createNotification}
                />

                {
                  user.role === "admin"
                  ? <DeleteFlow
                      deleteAction={deleteBlogger}
                      deleteWhat="blogger"
                    />
                  : <></>
                }
            </Wrapper>
        </form>
      </Box>
      <Box sx={{
            width: '400px',
            display: "flex",
            flexDirection: "column",
            '& > :not(style)': { m: 1 },
            maxWidth: '400px',
        }}>
          <h2>Comments</h2>

          {
            formik.values.comments.map((value, idx) => {
              return (
                <Card sx={{ minWidth: 275 }} key={idx}>
                  <CardContent>
                    <TextField
                      value={value.text}
                      variant="standard" 
                      multiline
                      disabled={canEditComment(user, value)}
                      sx={{width: '100%'}}
                      onChange={(event) => {
                        const before = formik.values.comments[idx];
                        before.text = event.target.value;
                        formik.setFieldValue('comments', formik.values.comments);
                      }}
                    />
                    <Typography color="text.secondary" sx={{font: 14}}>
                      {convertUtcToLocalTime(value.date)}
                      <Tooltip title="delete the comment">
                        <IconButton
                          size="small"
                          aria-label="delete"
                          disabled={canEditComment(user, value)}
                          onClick={
                            () => {
                              formik.values.comments.splice(idx, 1);
                              formik.setFieldValue('comments', formik.values.comments);
                              formik.submitForm();
                            }
                          }
                        >
                          <DeleteIcon/>
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="add notification">  
                        <IconButton
                          size="small"
                          aria-label="delete"
                          onClick= {
                            () => {
                              if (!id) {
                                throw new Error('id shold be set');
                              }
                              setPickDateDialogOpen(true)
                              setNotification({
                                bloggerId: id,
                                bloggerName: formik.values.name,
                                commentId: value.user.id + value.date,
                                text: value.text,
                              })
                            }
                          }
                        >
                          <NotificationAddIcon/>
                        </IconButton>
                      </Tooltip>
                      <br></br>
                      {value.user.name}
                    </Typography>

                    <TextField
                      value={"Notifications"}
                      variant="standard" 
                      multiline
                      disabled={true}
                      sx={{width: '100%'}}
                    />
                    <Typography color="text.secondary" sx={{font: 14}}>
                      {value.notifications.map((notification, notifIdx) => (
                        <div key={notification.id}>
                          <Typography variant="body2">
                            {convertUtcToLocalTime(notification.isoTime)}
                            
                            <Tooltip title="delete the notification">
                              <IconButton
                                size="small"
                                aria-label="delete"
                                disabled={canEditComment(user, value)}
                                onClick={
                                  () => {
                                    const newNots = formik.values.comments[idx].notifications.slice(notifIdx, 1);
                                    const newComs = formik.values.comments;
                                    newComs[idx].notifications = newNots;
                                    formik.setFieldValue('comments', newComs);
                                    deleteNotification(notification.id)
                                  }
                                }
                              >
                                <DeleteIcon/>
                              </IconButton>
                            </Tooltip>

                          </Typography>
                        </div>
                      ))}
                     </Typography>
                  </CardContent>
                </Card>
              )
            })
          }

          <TextField
            value={commentText}
            multiline
            label="New comment"
            id="new-comment"
            onChange={(event) => {
              setCommentText(event.target.value)
            }}
          />
          {AddCommentOrLoading}

          {
            (() => {
              if (user.role === "admin") {
                return (
                  <Box sx={{
                    width: '400px',
                    display: "flex",
                    flexDirection: "column",
                    '& > :not(style)': { m: 1 },
                    maxWidth: '400px',
                  }}>
                  <h2>History</h2>
                    {
                      formik.values.history.map((value, idx) => {
                        return (
                            <Card sx={{ minWidth: 275 }} key={idx}>
                              <CardContent>
                                <Typography color="text.secondary" sx={{font: 9}}>
                                  <pre style={{fontSize: 14}}>
                                    {stringify(value.changes)}
                                  </pre>
                                </Typography>
                                <Typography color="text.secondary" sx={{font: 14}}>
                                  {formatISODate(value.isoTime)}
                                  <br></br>
                                  {value.user.name}
                                </Typography>
                              </CardContent>
                            </Card>
                          )
                      })
                    }
                  </Box>
                  )
                } 
            })()
          }
      </Box>
      <Box sx={{
            width: '400px',
            display: "flex",
            flexDirection: "column",
            '& > :not(style)': { m: 1 },
            maxWidth: '400px',
        }}>
          <h2>Emails</h2>
          <NewEmailDialog mail={{to: formik.values.email}}/>
      </Box>
    </Box>
  )
};