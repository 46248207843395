import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import { AppContext } from '../AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import { CreatePublicRelationEvent, CreatePublicRelationResult,
  ValidateOnCreateResult, ValidateOnCreateEvent, PublicRelation } from 'bloggers-backend/lib/pr/domain';
import { languages } from 'bloggers-backend/lib/pr/dicts/languages';
import { mediaTypes } from 'bloggers-backend/lib/pr/dicts/mediaTypes';
import { topics } from 'bloggers-backend/lib/pr/dicts/topics';
import { Button, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';


export default function PublicRelationPage() {
  const {client, notificationsService} = useContext(AppContext);

  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const initialValues: PublicRelation = {
    id: '',
    name: '',
    createTime: '',
    createdBy: {
        id: '',
    },
    status: '',
    link: '',
    language: '',
    email: '',
    instagram: '',
    twitter: '',
    facebook: '',
    linkedIn: '',
    mediaType: '',
    topic: '',
    manager: {
        id: '',
        name: '',
    },
    comments: [],
    lastComment: null,
    history: [],
  }


  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues,
    validate: async (values) => {
      setLoading(true);
      const result = await client<ValidateOnCreateEvent, ValidateOnCreateResult>({
        event: 'onCreatePublicRelation',
        data: values
      });
      setLoading(false);
      if (!result.success) {
        return result.errors
      }
      setLoading(false);
    },
    onSubmit: async (values) => {
        setLoading(true);
        const result = await client<CreatePublicRelationEvent, CreatePublicRelationResult>({
            event: 'createPublicRelation',
            data: values
        })

        if (result.success) {
            notificationsService.notify({
                severity: 'success',
                message: 'PR created'
            });
            navigate(`/pr/${result.value}`);
        } else {
            notificationsService.notify({
                severity: 'error',
                message: `Error: ${JSON.stringify(result.errors)}`
            });
        }
        setLoading(false);
    },
  });

  const buttonOrLoading = 
      loading
      ? <CircularProgress />
      : (<Button
          variant="contained"
          type="submit">Submit
      </Button>)

  return (
    <form onSubmit={formik.handleSubmit}>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            '& > :not(style)': { m: 1 },
            maxWidth: '400px',
        }}>
            <h2>Create new PR</h2>
            <TextField
                id="name"
                name="name"
                label="name"
                variant="outlined"
                required
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                onChange={formik.handleChange}
                value={formik.values.name}
            />
            <TextField
                id="link"
                name="link"
                label="link"
                variant="outlined"
                required
                error={!!formik.errors.link}
                helperText={formik.errors.link}
                onChange={formik.handleChange}
                value={formik.values.link}
            />
            <Autocomplete
              id="language"
              onChange={(_, value) => formik.setFieldValue('language', value)}
              options={languages}
              value={formik.values.language}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!formik.errors.language}
                  helperText={formik.errors.language || ""}
                  id="language"
                  label="language"
                />}
            />  
            <TextField
                id="email"
                name="email"
                label="email"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.email || ""}
            />
            <Autocomplete
              id="mediaType"
              onChange={(_, value) => formik.setFieldValue('mediaType', value)}
              options={mediaTypes}
              value={formik.values.mediaType}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!formik.errors.mediaType}
                  helperText={formik.errors.mediaType || ""}
                  id="mediaType"
                  label="media type"
                />}
            />  
            <Autocomplete
              id="topic"
              onChange={(_, value) => formik.setFieldValue('topic', value)}
              options={topics}
              value={formik.values.topic}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!formik.errors.topic}
                  helperText={formik.errors.topic || ""}
                  id="topic"
                  label="topic"
                />}
            />  
            <TextField
                id="instagram"
                name="instagram"
                label="instagram"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.instagram || ""}
            />
            <TextField
                id="facebook"
                name="facebook"
                label="facebook"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.facebook || ""}
            />
            <TextField
                id="twitter"
                name="twitter"
                label="twitter"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.twitter || ""}
            />
            <TextField
                id="linkedIn"
                name="linkedIn"
                label="linkedIn"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.linkedIn || ""}
            />
            {buttonOrLoading}
        </Box>
    </form>
  )
 
}