import { Navigate, Route, Routes } from "react-router-dom";
import CreateBloggerPage from "../pages/CreateBloggerPage";
import BloggersPage from "../pages/BloggersPage";


export const CreatorView = () => (
  <Routes>
    <Route path="/" element={<Navigate to="/bloggers" />}></Route>

    <Route path="/bloggers" element={<BloggersPage/>}></Route>
    <Route path="/bloggers/new" element={<CreateBloggerPage/>}></Route>
</Routes>
)