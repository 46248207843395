import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box } from '@mui/system';
import { AppContext } from '../AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Template } from 'bloggers-backend/lib/templates/domain/Template';
import { CreateTemplateEvent, CreateTemplateResult, 
  DeleteTemplateEvent, 
  DeleteTemplateResult, 
  GetTemplateEvent, 
  GetTemplateResult, 
  UpdateTemplateEvent, UpdateTemplateResult } from 'bloggers-backend/lib/templates/domain/domain';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { convertUtcToLocalTime } from '../Utils';
import { DeleteFlow } from '../components/DeleteFlow';
import { TemplateTagLinkInput } from '../components/TemplateTagLinkInput';


export function TemplatePage() {
  const {client, notificationsService, user} = useContext(AppContext);

  let { id } = useParams();

  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [isCreator, setIsCreator] = React.useState(false);


  async function deleteTemplate() {
    setLoading(true);
  
    if (!id) {
      throw new Error('Can not delete template with no id');
    }
    const result = await client<DeleteTemplateEvent, DeleteTemplateResult>({
      event: 'deleteTemplate',
      data: {id}
    });

    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: 'Template deleted'
      });
      navigate(`/templates/`);
    } else {
      setLoading(false);
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      });
    }
  }

  const initialValues: Template = {
    id: '',
    name: '',
    title: '',
    text: '',
    tags: [],
    access: 'private',
    date: '',
    creator: {
        id: '',
        name: '',
    },

  }


  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
        setLoading(true);

        if (id) {
          const result = await client<UpdateTemplateEvent, UpdateTemplateResult>({
            event: 'updateTemplate',
            data: values
        })

        if (result.success) {
            notificationsService.notify({
                severity: 'success',
                message: 'template updated'
            });
        } else {
            notificationsService.notify({
                severity: 'error',
                message: `Error: ${JSON.stringify(result.errors)}`
            });
        }
        }
        else {
          const result = await client<CreateTemplateEvent, CreateTemplateResult>({
              event: 'createTemplate',
              data: values
          })

          if (result.success) {
              notificationsService.notify({
                  severity: 'success',
                  message: 'template created'
              });
              navigate(`/templates/${result.value}`);
          } else {
              notificationsService.notify({
                  severity: 'error',
                  message: `Error: ${JSON.stringify(result.errors)}`
              });
          }
      }
        setLoading(false);
    },
  });

  useEffect(() => {
    setIsCreator(true);
    if (!id) {
        return;
    } 

    (async () => {
        setLoading(true);
        if (id) {
        const result = await client<GetTemplateEvent, GetTemplateResult>(
            {event: 'getTemplate', data: {id}}
        )

        if (result.success) {
            formik.resetForm({values: result.value});
            setIsCreator(result.value.creator.name === user.name || user.role === 'admin');
        } else {
            notificationsService.notify({
                message: "Can't get a template",
                severity: "error",
            })
        }
        setLoading(false)
      }
    })()
    }, [id]);

  if (error) {
    return (<Box>{error}</Box>);
  }

  const header = id ? 'Edit a template' : 'Create a new template'

  const buttonOrLoading = 
      loading
      ? <CircularProgress />
      : (<Button
          variant="contained"
          type="submit">Submit
      </Button>)

  return (
    <form onSubmit={formik.handleSubmit}>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            '& > :not(style)': { m: 1 },
            maxWidth: '800px',
        }}>
            <h2>{header}</h2>
            <TextField
                id="name"
                name="name"
                label="name"
                variant="outlined"
                required
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                onChange={formik.handleChange}
                value={formik.values.name}
                disabled={!isCreator}
            />
            <TextField
                id="title"
                name="title"
                label="title"
                variant="outlined"
                required
                error={!!formik.errors.title}
                helperText={formik.errors.title}
                onChange={formik.handleChange}
                value={formik.values.title}
                disabled={!isCreator}
            />
            <TextField
                id="text"
                name="text"
                label="text"
                variant="outlined"
                required
                error={!!formik.errors.text}
                helperText={formik.errors.text}
                onChange={formik.handleChange}
                value={formik.values.text || ""}
                multiline
                rows={8} 
                disabled={!isCreator}
            />
            <TemplateTagLinkInput
              client={client}
              onChoose={(tags: string[]) => formik.setFieldValue('tags', tags)}
              value={(formik.values.tags || [])}
              label="tags"
              id="tags"
              variant="outlined"
            />

            <FormControl>
                <InputLabel id="access-lable">access</InputLabel>
                <Select
                    id="access"
                    required
                    name="access"
                    value={formik.values.access}
                    label="Access"
                    labelId='access-label'
                    onChange={formik.handleChange}
                    variant="outlined"
                    disabled={user.role != 'admin'}
                    >
                    <MenuItem value={'private'}>Private</MenuItem>
                    <MenuItem value={'default'}>Default</MenuItem>
                </Select>
            </FormControl> 
            {
                id !== undefined
                ? (
                    <>
                        <TextField
                            id="createdAt"
                            name="createdAt"
                            label="created at"
                            variant="outlined"
                            required
                            disabled={true}
                            value={convertUtcToLocalTime(formik.values.date)}
                        />
                        <TextField
                            id="creator"
                            name="creator"
                            label="creator"
                            variant="outlined"
                            disabled={true}
                            value={formik.values.creator.name}
                        /></>
                )
                :  <></> 
            }
            {buttonOrLoading}

            {
                id ? <DeleteFlow deleteWhat="template" deleteAction={deleteTemplate}/>
                   : <></>
            }
        </Box>
    </form>
  )
 
}