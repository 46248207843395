export const statuses = [
  "affiliate",
  "no contact",
  "pending",
  "dead channel",
  "no answer",
  "no host",
  "not interested",
  "paid review offer",
  "sponsored",
];
