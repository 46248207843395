import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Notification, NotificationService } from '../domain/Notifications';
import { Alert } from '@mui/material';
import { AppContext } from '../AppContext';

export default function Notifications() {
  const { notificationsService } = React.useContext(AppContext);
  const [open, setOpen] = React.useState(false);
  const [notifications, setNotifications] = React.useState<Notification[]>([]);


  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotifications([]);
    setOpen(false);
  };


  React.useEffect(() => {
    notificationsService.subscribe((notification) => {
        setOpen(true);
        setNotifications([
          ...notifications,
          notification
        ]);
    })
  })

  return (
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <div>
          {
            notifications.map((notification) =>
            (<Alert onClose={handleClose}
              severity={notification?.severity}
              sx={{ width: '100%' }}
              variant="filled"
              elevation={6}
              key={notification.message + notification.severity}
            >
              {notification?.message}
            </Alert>)
            )
          }
        </div>
      </Snackbar>
  );
}
