import { GetAllEmailAccounts, GetAllEmailAccountsResult } from 'bloggers-backend/lib/email/domain/events';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Grid, { QueryOptions } from './Grid';
import { AppContext } from '../AppContext';


const columns = [
  {
    field: 'email',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: ({row}: any) => (<Link to={`/email-accounts/${row?.id}`}>{row?.email}</Link>)
  },
];

export default function EmailAccounts() {
  const {client, notificationsService} = React.useContext(AppContext);

  const navigate = useNavigate();

  const fetchData = async (options: QueryOptions) => {
    const result = await client<GetAllEmailAccounts, GetAllEmailAccountsResult>({
      event: 'getAllEmailAccounts',
      data: options as any
    })

    if (result.success) {
      return result.value
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });

      return {data: [], pageInfo: {}}
    }
  };

  return <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Box sx={{marginBottom: 1}}>
      <Button
        variant="contained"
        onClick={() => navigate('/email-accounts/new')}
      >
        CREATE ACCOUNT
      </Button>
    </Box>
    <Box sx={{flexGrow: 1}}>
      <Grid
        columns={columns}
        cacheName={'email-accounts'}
        fetchData={fetchData}
        onRowClick={(id) => {}}
      />
    </Box>
  </Box>
}
