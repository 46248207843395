import * as React from 'react';
import { Button } from '@mui/material';
import { AppContext } from '../AppContext';
import { GetAuthUrl, GetAuthUrlResult } from 'bloggers-backend/lib/email/domain/events';


export const GetGoogleToken = () => {
  const {client, notificationsService} = React.useContext(AppContext);
  const [loading, setLoading] = React.useState(false);

  async function getAuthUrl() {
    setLoading(true);
    const result = await client<GetAuthUrl, GetAuthUrlResult>({
      event: 'getAuthUrl',
      data: {}
    })

    if (result.success) {
      window.open(result.value.url, "_self");
    } else {
      notificationsService.notify({
        severity: 'error',
        message: "Error: could not get goole's auth url. Try again"
      })
    }

    setLoading(false);
  }

  return (
    <>
      <Button
        disabled={loading}
        variant="contained"
        color="error"
        onClick={getAuthUrl}
      >Gmail</Button>
    </>
  );
}
