import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import * as React from 'react';
import { Client } from '../domain/client';


interface LinkInputProps<T extends {name: string}> extends OutlinedTextFieldProps {
  getOptions: () => Promise<T[]>
  onChoose: (user: T | null) => any;
  value: T | null
}

export default function LinkInput<T extends {name: string}>({onChoose, value, getOptions, label, id, ...props}: LinkInputProps<T>) {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<T[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        const result = await getOptions();
        setOptions(result);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id={id}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      value={value}
      onChange={(event, value) => {
        if (value) {
          onChoose(value)
        } else {
          onChoose(null);
        }
      }}
      onInputChange={(event, value) => setInputValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
