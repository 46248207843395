export const themes = [
  "photo",
  "photo/video",
  "video",
  "mobile",
  "music",
  "gadgets",
  "cgi",
  "lifestyle",
  "hobbies",
  "travel",
  "marketing",
  "CGI",
  "graphic design",
  "architecture",
];
