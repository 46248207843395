export const hosts = [
  "Premiere Pro Windows",
  "Premiere Pro Mac",
  "After Effects Windows",
  "After Effects Mac",
  "DaVinci Resolve Windows",
  "DaVinci Resolve Mac",
  "Final Cut",
  "Photoshop Windows",
  "Photoshop Mac",
  "Capture One Windows",
  "Capture One Mac",
  "Affinity Photo Windows",
  "Affinity Photo Mac",
  "Lightroom Classic",
  "Lightroom Mac",
  "iOS",
  "Dehancer Online",
];
