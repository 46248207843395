import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { DeletePublicRelationEvent, DeletePublicRelationResult, GetPublicRelationEvent, GetPublicRelationResult, 
  UpdatePublicRelationEvent, UpdatePublicRelationResult,
  ValidateOnUpdateEvent, ValidateOnUpdateResult, PublicRelation } from 'bloggers-backend/lib/pr/domain';
import { statuses } from 'bloggers-backend/lib/pr/dicts/statuses';
import { languages } from 'bloggers-backend/lib/pr/dicts/languages';
import { mediaTypes } from 'bloggers-backend/lib/pr/dicts/mediaTypes';
import { topics } from 'bloggers-backend/lib/pr/dicts/topics';
import { Box } from '@mui/system';
import { Button, Card, CardContent, IconButton, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { DeleteFlow } from '../components/DeleteFlow';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { convertUtcToLocalTime, formatISODate } from '../Utils';
import {stringify} from 'yaml';
import { UserLinkInput } from '../components/UserLinkInput';

interface User {
  role: string
  id: string
  name: string
}

function canEditComment(user: User, comment: {user: {id: string}}) {
  return user.role !== "admin" && comment.user.id !== user.id
}

export default function PublicRelationEditPage() {
  const {client, notificationsService, user} = useContext(AppContext);

  let { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [commentText, setCommentText] = React.useState('');

  const initialValues: PublicRelation = {
    id: '',
    name: '',
    createTime: '',
    createdBy: {
        id: '',
    },
    status: '',
    link: '',
    language: '',
    email: '',
    instagram: '',
    twitter: '',
    facebook: '',
    linkedIn: '',
    mediaType: '',
    topic: '',
    manager: {
        id: '',
        name: '',
    },
    comments: [],
    lastComment: null,
    history: [],
  }

  async function reloadForm(id: string) {
    setLoading(true);
    const result = await client<GetPublicRelationEvent, GetPublicRelationResult>({
      event: 'getPublicRelation',
      data: {id}
    });

    if (result.success) {
      formik.resetForm({
        values: {...initialValues, ...result.value}
      })
    } else {
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      })
    }

    setLoading(false)
  }

  async function deletePR() {
    setLoading(true);
  
    if (!id) {
      throw new Error('Can not delete pr with no id');
    }
    const result = await client<DeletePublicRelationEvent, DeletePublicRelationResult>({
      event: 'deletePublicRelation',
      data: {id}
    });

    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: 'PR deleted'
      });
      navigate(`/pr/`);
    } else {
      setLoading(false);
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      });
    }
  }

  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik({
    initialValues,
    validate: async (values) => {
      setLoading(true);
      const result = await client<ValidateOnUpdateEvent, ValidateOnUpdateResult>({
        event: 'onUpdatePublicRelation',
        data: values
      });
      setLoading(false);
      if (!result.success) {
        console.log(result.errors);
        return result.errors
      }
      
      setLoading(false);
    },
    onSubmit: async (values) => {
        setLoading(true);
        const result = await client<UpdatePublicRelationEvent, UpdatePublicRelationResult>({
            event: 'updatePublicRelation',
            data: values
        })

        if (result.success) {
            notificationsService.notify({
                severity: 'success',
                message: 'PR updated'
            });
        } else {
            notificationsService.notify({
                severity: 'error',
                message: `Error: ${JSON.stringify(error)}`
            });
        }

        setLoading(false);
    },
  });

  useEffect(() => {
    if (!id) {
        return;
    } reloadForm(id);
  }, [id]);

  const buttonOrLoading = 
  loading
  ? <CircularProgress />
  : (<Button
      variant="contained"
      type="submit">Submit
  </Button>)

  const AddCommentOrLoading = 
  loading
  ? <CircularProgress />
  : (
    <Button
      disabled={commentText === ''}
      onClick={() => {
        const newComments = formik.values.comments.concat([{
          text: commentText,
          date: new Date().toISOString(),
          user,
        }])

        formik.setFieldValue('comments', newComments)
        setCommentText("");
        formik.handleSubmit()
      }}
      variant="contained"
      type="button">Add comment
    </Button>
  )

  return (
    <Box sx={{display: 'flex', flexDirection: 'row', height: '100%' }}>
      <Box sx={{width: '400px'}}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{
              display: "flex",
              flexDirection: "column",
              '& > :not(style)': { m: 1 },
              maxWidth: '400px',
          }}>
              <h2>Edit PR</h2>
              <TextField
                id="name"
                name="name"
                label="name"
                variant="outlined"
                required
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                onChange={formik.handleChange}
                value={formik.values.name}
            />
            <TextField
                id="link"
                name="link"
                label="link"
                variant="outlined"
                required
                error={!!formik.errors.link}
                helperText={formik.errors.link}
                onChange={formik.handleChange}
                value={formik.values.link}
            />
            <Autocomplete
              id="status"
              onChange={(_, value) => formik.setFieldValue('status', value)}
              options={statuses}
              value={formik.values.status}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!formik.errors.status}
                  helperText={formik.errors.status || ""}
                  id="status"
                  label="status"
                />}
              />
            <Autocomplete
              id="language"
              onChange={(_, value) => formik.setFieldValue('language', value)}
              options={languages}
              value={formik.values.language}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!formik.errors.language}
                  helperText={formik.errors.language || ""}
                  id="language"
                  label="language"
                />}
            />  
            <TextField
                id="email"
                name="email"
                label="email"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.email}
            />
            <Autocomplete
              id="mediaType"
              onChange={(_, value) => formik.setFieldValue('mediaType', value)}
              options={mediaTypes}
              value={formik.values.mediaType}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!formik.errors.mediaType}
                  helperText={formik.errors.mediaType || ""}
                  id="mediaType"
                  label="media type"
                />}
            />  
            <Autocomplete
              id="topic"
              onChange={(_, value) => formik.setFieldValue('topic', value)}
              options={topics}
              value={formik.values.topic}
              renderInput={(params) =>
                <TextField {...params}
                  error={!!formik.errors.topic}
                  helperText={formik.errors.topic || ""}
                  id="topic"
                  label="topic"
                />}
            />  
            <TextField
                id="instagram"
                name="instagram"
                label="instagram"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.instagram}
            />
            <TextField
                id="facebook"
                name="facebook"
                label="facebook"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.facebook}
            />
            <TextField
                id="twitter"
                name="twitter"
                label="twitter"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.twitter}
            />
            <TextField
                id="linkedIn"
                name="linkedIn"
                label="linkedIn"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.linkedIn}
            />
              <UserLinkInput client={client}
                id="manager"
                name="manager"
                label="manager"
                variant="outlined"
                required
                error={!!formik.errors.manager}
                
                onChoose={(manager) => {
                  formik.setFieldValue('manager', manager)
                }}
                value={formik.values.manager}
              />

            {buttonOrLoading}

          {
              id ? <DeleteFlow deleteWhat="PR" deleteAction={deletePR}/>
                  : <></>
          }
        
        </Box>
        </form>
      </Box>
      <Box sx={{
            width: '400px',
            display: "flex",
            flexDirection: "column",
            '& > :not(style)': { m: 1 },
            maxWidth: '400px',
        }}>
          <h2>Comments</h2>
          {
            formik.values.comments.map((value, idx) => {
              return (
                <Card sx={{ minWidth: 275 }} key={idx}>
                  <CardContent>
                    <TextField
                      value={value.text}
                      variant="standard" 
                      multiline
                      disabled={canEditComment(user, value)}
                      sx={{width: '100%'}}
                      onChange={(event) => {
                        const before = formik.values.comments[idx];
                        before.text = event.target.value;
                        formik.setFieldValue('comments', formik.values.comments);
                      }}
                    />
                    <Typography color="text.secondary" sx={{font: 14}}>
                      {convertUtcToLocalTime(value.date)}
                      <Tooltip title="delete the comment">
                        <IconButton
                          size="small"
                          aria-label="delete"
                          disabled={canEditComment(user, value)}
                          onClick={
                            () => {
                              formik.values.comments.splice(idx, 1);
                              formik.setFieldValue('comments', formik.values.comments);
                              formik.submitForm();
                            }
                          }
                        >
                          <DeleteIcon/>
                        </IconButton>
                      </Tooltip>
                      <br></br>
                      {value.user.name}
                    </Typography>

                  </CardContent>
                </Card>
              )
            })
          }

          <TextField
            value={commentText}
            multiline
            label="New comment"
            id="new-comment"
            onChange={(event) => {
              setCommentText(event.target.value)
            }}
          />
          {AddCommentOrLoading}
      </Box>    
      <Box>
        {
          (() => {
            if (user.role === "admin") {
              return (
                <Box sx={{
                  width: '400px',
                  display: "flex",
                  flexDirection: "column",
                  '& > :not(style)': { m: 1 },
                  maxWidth: '400px',
                }}>
                <h2>History</h2>
                  {
                    formik.values.history && formik.values.history.map((value, idx) => {
                      return (
                          <Card sx={{ minWidth: 275 }} key={idx}>
                            <CardContent>
                              <Typography color="text.secondary" sx={{font: 9}}>
                                <pre style={{fontSize: 14}}>
                                  {stringify(value.changes)}
                                </pre>
                              </Typography>
                              <Typography color="text.secondary" sx={{font: 14}}>
                                {formatISODate(value.isoTime)}
                                <br></br>
                                {value.user.name}
                              </Typography>
                            </CardContent>
                          </Card>
                        )
                    })
                  }
                </Box>
                )
              } 
          })()
        }
      </Box>
    </Box>  
  )

}