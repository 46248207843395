import React from "react";
import { Formik } from 'formik';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {post} from "bloggers-backend/lib/client";
import { TextField } from "../components/forms/TextField";
import { Form } from "../components/forms/Form";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";


export const Login = () => {
  const navigate = useNavigate();

  return <Formik
    initialValues={{ email: '', password: '' }}
    onSubmit={async (values, actions) => {
      const result = await post('/auth/login', values);

      if (result.success) {
        window.location.href = '/';
      } else {
        actions.setStatus(result.reason);
        actions.setErrors(result.errors);
        actions.setSubmitting(false);
      }
    }}
  >
    {({ status }) => (
      <Form>
        {status ? <Alert severity="warning">{status}</Alert> : <></>}

        <TextField name="email" label="email"/>
        <TextField name="password" label="password" type="password"/>

        <Button
            variant="contained"
            type="submit">Login
        </Button>
        <Button variant="text" onClick={() => navigate('/reset-password')}>Forgot password</Button>
      </Form>
    )}
  </Formik>
};
