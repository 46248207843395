import { Navigate, Route, Routes } from "react-router-dom";
import CreateBloggerPage from "../pages/CreateBloggerPage";
import BloggersPage from "../pages/BloggersPage";
import EditBloggerPage from "../pages/EditBloggerPage";
import SearchTasksPage from "../pages/SearchTasksPage";
import SearchTaskPage from "../pages/SearchTaskPage";
import NotificationsPage from "../pages/NotificationsPage";
import { Box } from "@mui/material";
import AppBar from "../AppBar";
import PublicRelationsPage from "../pages/PublicRelationsPage";
import PublicRelationPage from "../pages/PublicRelationPage";
import PublicRelationEditPage from "../pages/PublicRelationEditPage";
import { TemplatesPage } from "../pages/TemplatesPage";
import { TemplatePage } from "../pages/TemplatePage";


export const ManagerView = () => (
  <>
  <AppBar pages={
    [
      {
        label: 'Bloggers',
        route: '/bloggers'
      },
      {
        label: 'Search tasks',
        route: '/search-tasks'
      },
      {
        label: 'Notifications',
        route: '/notifications'
      },
      {
        label: 'PR',
        route: '/pr'
      },
      {
        label: 'Templates',
        route: '/templates'
      },
    ]
  }/>
  <Box sx={{ p: 3, flexGrow: 1}}>
    <Routes>
      <Route path="/" element={<Navigate to="/bloggers" />}></Route>

      <Route path="/bloggers" element={<BloggersPage/>}></Route>
      <Route path="/bloggers/new" element={<CreateBloggerPage/>}></Route>
      <Route path="/bloggers/:id" element={<EditBloggerPage/>}></Route>

      <Route path="/search-tasks" element={<SearchTasksPage/>}></Route>
      <Route path="/search-tasks/new" element={<SearchTaskPage/>}></Route>
      <Route path="/search-tasks/:id" element={<SearchTaskPage/>}></Route>

      <Route path="/pr" element={<PublicRelationsPage/>}></Route>
      <Route path="/pr/new" element={<PublicRelationPage />}></Route>
      <Route path="/pr/:id" element={<PublicRelationEditPage />}></Route>

      <Route path="/notifications" element={<NotificationsPage/>}></Route>

      <Route path="/templates" element={<TemplatesPage/>}></Route>
      <Route path="/templates/new" element={<TemplatePage/>}></Route>
      <Route path="/templates/:id" element={<TemplatePage/>}></Route>
    </Routes>
  </Box>
  </>
)