import { Formik, useFormikContext } from 'formik';
import { useContext, useState } from 'react';
import { Box, Button } from '@mui/material';
import { TemplateLinkInput } from '../TemplateLinkInput';
import { Template } from 'bloggers-backend/lib/templates/domain/Template';
import { TextField } from '../forms/TextField';
import { Form } from '../forms/Form';
import { AccountWithName, EmailAccountLinkInput } from '../EmailAccountLinkInput';
import { AppContext } from '../../AppContext';
import { SendEmailEvent, SendEmailResult } from 'bloggers-backend/lib/email/domain/events';

export interface EmailProps {
  to?: string | null
  from?: string | null
}

interface SendMailProps {
  mail?: EmailProps
  onClose?: () => void
}

export const SendEmail = (props: SendMailProps) => {
  const {client, notificationsService} = useContext(AppContext);
  return <Formik
    initialValues={{
      from: '', to: props.mail?.to || '', subject: '', text: ''
    }}
    onSubmit={async (values) => {
      const result = await client<SendEmailEvent, SendEmailResult>({
        event: "sendEmail",
        data: values
      });

      if (result.success) {
        notificationsService.notify({
          severity: "success",
          message: `the message has been sent to ${values.to}`
        })

        if (props.onClose) {
          props.onClose()
        }
      } else {
        notificationsService.notify({
          severity: "error",
          message: `the message hasn't been sent. error: ${result.reason} ${JSON.stringify(result.errors)}`
        })
      }
    }}
  >
    <Form>
      <FromPicker/>
      <TextField name="to" label="to"/>
      <TemplatePicker/>
      <TextField name="subject" label="subject"/>
      <TextField name="text" label="text" multiline minRows={8}/>
      <Button type="submit" variant="contained">Send</Button>
    </Form>
  </Formik>
};


const FromPicker = () => {
  const [account, setAccount] = useState<AccountWithName | null>(null)
  const formik = useFormikContext();

  return <EmailAccountLinkInput
    onChoose={(account) => {
      setAccount(account);
      if (account) {
        formik.setFieldValue('from', account.email)
      }
    }}
    value={account}
  />
}

const TemplatePicker = () => {
  const [template, setTemplate] = useState<Template | null>(null)
  const formik = useFormikContext();

  return <TemplateLinkInput
    onChoose={(template) => {
      setTemplate(template);
      if (template) {
        formik.setFieldValue('subject',template.title)
        formik.setFieldValue('text',template.text)
      }
    }}
    value={template}
  />
}