import { GetAllUsersEvent, GetAllUsersResult } from 'bloggers-backend/lib/users/domain';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Grid, { QueryOptions } from './Grid';
import { AppContext } from '../AppContext';


const columns = [
  {
    field: 'name',
    width: 150,
    filterable: false,
    sortable: false,
    renderCell: ({row}: any) => (<Link to={`/users/${row?.id}`}>{row?.name}</Link>)
  },
  { field: 'email', width: 150, filterable: false, sortable: false },
  { field: 'role', width: 150, filterable: false, sortable: false },
];

export default function UsersPage() {
  const {client, notificationsService} = React.useContext(AppContext);

  const navigate = useNavigate();

  const fetchData = async (options: QueryOptions) => {
    const result = await client<GetAllUsersEvent, GetAllUsersResult>({
      event: 'getAllUsers',
      data: options as any
    })

    if (result.success) {
      return result.value
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });

      return {data: [], pageInfo: {}}
    }
  };

  return <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Box sx={{marginBottom: 1}}>
      <Button
        variant="contained"
        onClick={() => navigate('/users/new')}
      >
        CREATE NEW USER
      </Button>
    </Box>
    <Box sx={{flexGrow: 1}}>
      <Grid
        columns={columns}
        cacheName={'users'}
        fetchData={fetchData}
        onRowClick={(id) => {}}
      />
    </Box>
  </Box>
}
