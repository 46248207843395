import React from "react";
import { Formik, useFormikContext } from 'formik';
import { TextField } from "../components/forms/TextField";
import { Form } from "../components/forms/Form";
import { useParams } from "react-router-dom";
import { AppContext } from "../AppContext";
import { GetEmailAccount, GetEmailAccountResult } from "bloggers-backend/lib/email/domain/events";

export const EmailAccountPage = () => {
  return <Formik
    initialValues={{ email: '', service: '' }}
    onSubmit={() => {}}
  >
    <Form>
      <h2>Email account</h2>
      <TextField disabled name="email" label="email"/>
      <TextField disabled name="service" label="service"/>
      <DataLoader/>
    </Form>
  </Formik>
};

const DataLoader = () => {
  const {id} = useParams();
  const { resetForm } = useFormikContext();
  const {notificationsService, client} = React.useContext(AppContext)
  const [loading, setLoading] = React.useState(false);

  async function loadData(id: string) {
    setLoading(true);
    const result = await client<GetEmailAccount, GetEmailAccountResult>({
      event: 'getEmailAccount',
      data: {id}
    });

    if (result.success) {
      resetForm({
        values: {...result.value}
      })
    } else {
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      })
    }

    setLoading(false)
  }

  React.useEffect(() => {
    if (!id || loading) {
      return;
    }


    loadData(id);
  }, [id]);

  return null
}