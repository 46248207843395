import * as React from 'react';
import { Client } from '../domain/client';
import LinkInput from './LinkInput';
import { EmailAccount } from 'bloggers-backend/lib/email/domain/EmailAccount';
import { GetAllEmailAccounts, GetAllEmailAccountsResult } from 'bloggers-backend/lib/email/domain/events';
import { AppContext } from '../AppContext';


export interface AccountWithName extends EmailAccount {
  name: string
}


interface EmailAccountLinkInputProps {
  onChoose: (user: AccountWithName | null) => any;
  value: AccountWithName | null
}

async function getEmailAccounts(client: Client) {
  const result = await client<GetAllEmailAccounts, GetAllEmailAccountsResult>({
    event: 'getAllEmailAccounts',
    data: {}
  });

  if (result.success) {
    return result.value.data.map(email => ({...email, name: email.email}))
  }

  throw new Error("getAllEmailAccounts failed");
}

export function EmailAccountLinkInput({onChoose, value}: EmailAccountLinkInputProps) {
  const {client} = React.useContext(AppContext);

  return <LinkInput<AccountWithName>
    variant="outlined"
    label="account"
    onChoose={onChoose}
    value={value}
    getOptions={() => getEmailAccounts(client)}
  />
}
