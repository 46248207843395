import { createContext } from "react";
import SimpleNotificationService from "./notifications/NotificationService";
import { Client } from "./domain/client";
import { NotificationService } from "./domain/Notifications";


export async function client<Req, Res>(req: Req): Promise<Res> {
  const respose = await fetch('/handler', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
  });

  if (respose.ok) {
    return await respose.json() as Res;
  } else {
    return {success: false} as Res
  }
}

interface AppContextProps {
  notificationsService: NotificationService;
  client: Client;
  user: {
    id: string;
    name: string;
    email: string;
    role: string
  };
  signOut: () => void
}


export async function signOut () {
  window.location.href = '/auth/logout';
}

export const AppContext = createContext<AppContextProps>({
  client,
  notificationsService: new SimpleNotificationService(),
  user: { 
    id: "",
    name: "",
    email: "",
    role: ""
  },
  signOut
});
