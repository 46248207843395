import * as React from 'react';
import { Client } from '../domain/client';
import LinkInput from './LinkInput';
import { Template } from 'bloggers-backend/lib/templates/domain/Template';
import { GetAllTemplateEvent, GetAllTemplateResult } from 'bloggers-backend/lib/templates/domain/domain';
import { AppContext } from '../AppContext';

interface TemplateLinkInputProps {
  onChoose: (user: Template | null) => any;
  value: Template | null
}

async function getTemplates(client: Client) {
  const result = await client<GetAllTemplateEvent, GetAllTemplateResult>({
    event: 'getAllTemplates',
    data: {}
  });

  if (result.success) {
    return result.value.data
  }

  throw new Error("getAllTemplates failed");
}

export function TemplateLinkInput({onChoose, value}: TemplateLinkInputProps) {
  const {client} = React.useContext(AppContext);

  return <LinkInput<Template>
    variant="outlined"
    label="template"
    onChoose={onChoose}
    value={value}
    getOptions={() => getTemplates(client)}
  />
}
