import * as React from 'react';
import { OutlinedTextFieldProps } from '@mui/material';
import { Client } from '../domain/client';
import { GetTemplateTagsEvent, GetTemplateTagsResult } from 'bloggers-backend/lib/templates/domain/domain';
import CircularProgress from '@mui/material/CircularProgress';

import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';


interface LinkInputProps extends OutlinedTextFieldProps {
  onChoose: (tags: string[]) => any;
  client: Client
  value: string[] | undefined
}

async function getTemplateTags(client: Client): Promise<string[]> {
  const result = await client<GetTemplateTagsEvent, GetTemplateTagsResult>({
    event: 'getTemplateTags',
  });

  if (result.success) {
    return result.value;
  }

  throw new Error("getTemplateTags failed");
}

export function TemplateTagLinkInput({client, onChoose, value, label, ...props}: LinkInputProps) {

  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<string[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return;
    }

    (async () => {
      if (active) {
        const result = await getTemplateTags(client);
        setOptions(result);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      multiple
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      loading={loading}
      value={value}
      onChange={(event, newValue) => {
        onChoose(newValue as string[]);
      }}
      onInputChange={(event, value) => setInputValue(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );

}