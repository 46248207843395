import React from "react";
import { Formik } from 'formik';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {post} from "bloggers-backend/lib/client";
import { TextField } from "../components/forms/TextField";
import { Form } from "../components/forms/Form";
import { Alert } from "@mui/material";
import { useParams } from "react-router-dom";


export const ChangePassword = () => {
  const {token} = useParams();

  if (!token) {
    return <Box><Alert>Wrong url</Alert></Box>;
  }

  return <Formik
    initialValues={{ password: ''}}
    onSubmit={async (values, actions) => {
      const result = await post('/auth/change-password', {password: values.password, token: token});

      if (result.success) {
        actions.setStatus({severity: 'success', text: (<>Password changed successfully. Go to <a href="/login">Login</a> page</>)});
      } else {
        actions.setStatus({severity: 'warning', text: result.reason});
        actions.setErrors(result.errors);
        actions.setSubmitting(false);
      }
    }}
  >
    {({ status }) => (
      <Form>
        {status ? <Alert severity={status.severity}>{status.text}</Alert> : <></>}

        {status && status.severity === 'success'
          ? <></>
          : <>
            <TextField name="password" label="password" type="password"/>

            <Button
                variant="contained"
                type="submit">Change password
            </Button>
            </>
          }
      </Form>
    )}
  </Formik>
};
