import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Box from '@mui/material/Box';
import SimpleNotificationService from './notifications/NotificationService';
import Notifications from './notifications/Notifications';
import { WhoAmIEvent, WhoAmIResult } from 'bloggers-backend/lib/users/domain';
import { CircularProgress } from '@mui/material';

import { LicenseInfo } from '@mui/x-license-pro';
import { LoggedOutView } from './views/LoggedOutView';
import { client } from './AppContext';
import { AdminView } from './views/AdminView';
import { ManagerView } from './views/ManagerView';
import { CreatorView } from './views/CreatorView';
import { AppContext, signOut } from './AppContext';

LicenseInfo.setLicenseKey('5e4c0abf1036faefb826f99b3a4a507eTz03NTYzNCxFPTE3Mjc1NDA0NjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Box sx={{width: '100vw', height: '100vh',display: 'flex', alignItems: "center"}}>
    <CircularProgress sx={{margin: "0 auto"}}/>
  </Box>
);

(async () => {
  const queryClient = new QueryClient()
  
  const whoAmI = await client<WhoAmIEvent, WhoAmIResult>({
    event: 'whoAmI',
    data: null
  });

  if (whoAmI.success) {
    const user = whoAmI.value;

    root.render(
      <React.StrictMode>
        <Router>
          < AppContext.Provider value={{
              notificationsService: new SimpleNotificationService(),
              client,
              user,
              signOut
          }}>
            <QueryClientProvider client={queryClient}>
              <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                {
                  (() => {
                    if (user.role === "admin") {
                      return <AdminView/>;
                    } else if (user.role === "manager") {
                      return  <ManagerView/>;
                    } else if (user.role === "creator") {
                      return <CreatorView/>;
                    }
                  })()
                }
              </Box>
              <Notifications/>
            </QueryClientProvider>
          </AppContext.Provider>
        </Router>
      </React.StrictMode>
    );
  } else {
    root.render(<LoggedOutView/>)
  }
  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();
