import * as React from "react";
import { useField } from 'formik';
import MUITextField, {TextFieldProps as MUIProps} from '@mui/material/TextField';


interface TextFieldProps extends Omit<MUIProps, "variant"> {
  label: string;
  name: string;
  type?: string;
}

export const TextField = (props: TextFieldProps) => {
  const [field, meta] = useField(props.name);
  return (
    <MUITextField
      {...props}
      id={field.name}
      name={field.name}
      variant="outlined"
      error={!!meta.error}
      helperText={meta.error}
      onChange={field.onChange}
      value={field.value}
    />
  );
};
