import * as React from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { AppContext } from '../AppContext';
import { GetTimesheetEvent, GetTimesheetResult, Timesheet } from 'bloggers-backend/lib/timesheetReport/domain';
import { convertUtcToLocalTime } from '../Utils';
import LinkInput from '../components/LinkInput';
import { UserLinkInput } from '../components/UserLinkInput';

interface User {
  id: string,
  name: string,
}

export function TimesheetPage() {
  const {notificationsService, client} = React.useContext(AppContext);

  const [dateRange, setDateRange] = React.useState<DateRange<Dayjs>>([
    dayjs().subtract(1, 'month'), dayjs().add(1, 'day')
  ]);
  const [timesheet, setTimesheet] = React.useState<Timesheet>([]);
  const [user, setUser] = React.useState<User>();

  const onClick = async () => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) {
      return;
    }
    const result = await client<GetTimesheetEvent, GetTimesheetResult>({
      event: 'getTimesheet',
      data: {
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
        userName: user?.name || "",
      }
    })
  
    if (result.success) {
      setTimesheet(result.value);
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });
  
      
    }
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div style={{ marginBottom: '20px' }}>
        <DateRangePicker 
          value={dateRange}
          onChange={(newValue) => setDateRange(newValue)}
          format="DD/MM/YYYY"
          localeText={{ start: 'start date', end: 'end date' }} 
          
        />
        </div>
      </LocalizationProvider>
      <UserLinkInput client={client}
                id="manager"
                name="manager"
                label="manager"
                variant="outlined"
                required
                onChoose={(manager) => {
                  setUser(manager ? manager : undefined)
                }}
                value={user || null}
              />
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Button onClick={onClick}>get report</Button>
      </div>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold', width: '20%'}}>manager name</TableCell>
            <TableCell style={{ fontWeight: 'bold', width: '20%'}}>time</TableCell>
            <TableCell style={{ fontWeight: 'bold', width: '20%'}}>blogger/pr name</TableCell>
            <TableCell style={{ fontWeight: 'bold', width: '40%'}}>changes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          timesheet.map(row => {
            return (
              <TableRow key={crypto.randomUUID()}>
                <TableCell>{row.userName}</TableCell>
                <TableCell>{convertUtcToLocalTime(row.time)}</TableCell>
                <TableCell>{row.entityName}</TableCell>
                <TableCell>{JSON.stringify(row.changes, null, 4)}</TableCell>
              </TableRow>
            );
          })
        }
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );

}