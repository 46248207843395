import {LoginData} from "./auth/services/login";
import {ResetPasswordData} from "./auth/services/resetPassword";
import {ChangePasswordData} from "./auth/services/changePassword";

import {Result, failure} from "./domain/Result";

export function post(url: "/auth/reset-password", data: ResetPasswordData): Promise<Result<string>>;
export function post(url: "/auth/change-password", data: ChangePasswordData): Promise<Result<string>>;
export function post(url: "/auth/login", data: LoginData): Promise<Result<string>>;

export async function post(url: string, data: unknown): Promise<any> {
  const response = await fetch(
    url, {method: "POST", body: JSON.stringify(data), headers: {"Content-Type": "application/json"}});


  if (response.ok) {
    return await response.json();
  } else {
    return failure("network");
  }
}
