import * as React from 'react';
import { DeleteDiaglog } from './DeleteDialog';
import { Button } from '@mui/material';

interface DeleteFlowProps {
  deleteWhat: string;
  deleteAction: () => void
}


export const DeleteFlow = ({deleteWhat, deleteAction}: DeleteFlowProps) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  return (
    <>
      <DeleteDiaglog
        isOpen={deleteDialogOpen}
        deleteWhat={deleteWhat}
        userRejects={() => setDeleteDialogOpen(false)}
        userAccepts={() => {
          setDeleteDialogOpen(false);
          deleteAction();
        }}
      />
      <Button
        variant="contained"
        color="error"
        onClick={() => setDeleteDialogOpen(true)}
      >DELETE</Button>
    </>
  );
}
