import React, { useContext, useEffect } from 'react';
import { useFormik } from 'formik';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { CreateUserEvent, CreateUserResult, DeleteUserEvent, DeleteUserResult, GetUserEvent, GetUserResult, UpdateUserEvent, UpdateUserResult, User } from 'bloggers-backend/lib/users/domain';
import { DeleteFlow } from '../components/DeleteFlow';
import { AppContext } from '../AppContext';

export default function UserPage() {
  const {client, notificationsService} = useContext(AppContext);

  let { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  async function deleteUser() {
    setLoading(true);
  
    if (!id) {
      throw new Error('Can not delete user with no id');
    }
    const result = await client<DeleteUserEvent, DeleteUserResult>({
      event: 'deleteUser',
      data: {id}
    });

    if (result.success) {
      notificationsService.notify({
        severity: 'success',
        message: 'User deleted'
      });
      navigate(`/users/`);
    } else {
      setLoading(false);
      notificationsService.notify({
        severity: 'error',
        message: JSON.stringify(result.errors)
      });
    }
  }

  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted
  const formik = useFormik<User>({
    initialValues: {
      id: '',
      email: '',
      name: '',
      role: '',
      youtubeApiKey: ''
    },
    onSubmit: async (values) => {
        setLoading(true);
        if (id) {
            const result = await client<UpdateUserEvent, UpdateUserResult>({
                event: 'updateUser',
                data: values
            })

            if (result.success) {
                notificationsService.notify({
                    severity: 'success',
                    message: 'User updated'
                });
            } else {
                notificationsService.notify({
                    severity: 'error',
                    message: `Error: ${JSON.stringify(error)}`
                });
            }
        } else {
            const result = await client<CreateUserEvent, CreateUserResult>({
                event: 'createUser',
                data: values
            })

            if (result.success) {
                notificationsService.notify({
                    severity: 'success',
                    message: 'User created'
                });
                navigate(`/users/${result.value}`);
            } else {
                notificationsService.notify({
                    severity: 'error',
                    message: `Error: ${JSON.stringify(result.errors)}`
                });
            }
        }
        setLoading(false);
    },
  });
    useEffect(() => {
        if (!id) {
            return;
        }

        (async () => {
            setLoading(true)
            const result = await client<GetUserEvent, GetUserResult>(
                {event: 'getUser', data: {id}}
            )

            if (result.success) {
                formik.resetForm({values: result.value})
            } else {
                notificationsService.notify({
                    message: "Can't get a user",
                    severity: "error",
                })
            }
            setLoading(false)
        })()
    }, [id]);

    if (error) {
        return (<Box>{error}</Box>);
    }

    const header = id ? 'Edit a user' : 'Create a new user'

    const buttonOrLoading = 
        loading
        ? <CircularProgress />
        : (<Button
            variant="contained"
            type="submit">Submit
        </Button>)

  return (
    <form onSubmit={formik.handleSubmit}>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            '& > :not(style)': { m: 1 },
            maxWidth: '400px',
        }}>
            <h2>{header}</h2>
            <TextField
                id="email"
                name="email"
                label="email"
                variant="outlined"
                required
                onChange={formik.handleChange}
                value={formik.values.email}
            />
            <TextField
                id="name"
                name="name"
                label="Name"
                variant="outlined"
                required
                onChange={formik.handleChange}
                value={formik.values.name}
            />

            <FormControl>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                    id="role"
                    required
                    name="role"
                    value={formik.values.role}
                    label="Role"
                    labelId='role-label'
                    onChange={formik.handleChange}
                    variant="outlined"
                >
                    <MenuItem value={'admin'}>Admin</MenuItem>
                    <MenuItem value={'manager'}>Manager</MenuItem>
                    <MenuItem value={'creator'}>Blogger Creator</MenuItem>
                </Select>
            </FormControl>

            <TextField
                id="youtubeApiKey"
                name="youtubeApiKey"
                label="Youtube Api Key"
                variant="outlined"
                onChange={formik.handleChange}
                value={formik.values.youtubeApiKey}
            />

            {buttonOrLoading}

            {
                id ? <DeleteFlow deleteWhat="user" deleteAction={deleteUser}/>
                   : <></>
            }
        </Box>
    </form>
  )
};