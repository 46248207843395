import * as React from 'react';
import { GridColumns, GridFilterModel, GridLinkOperator, GridRowModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useQuery } from '@tanstack/react-query';
import { DataGridPro } from '@mui/x-data-grid-pro';


interface PageInfo {
  totalRowCount?: number;
  nextCursor?: string;
  pageSize?: number;
}

export interface QueryOptions {
  cursor?: string;
  page?: number;
  pageSize?: number;
  filterModel?: GridFilterModel;
  sortModel?: GridSortModel;
}

export interface QueryResult {
    pageInfo: PageInfo;
    data: GridRowModel[];
}

export interface GridProps {
  columns: GridColumns;
  cacheName: string;
  onRowClick: (id: string | number) => void;
  fetchData: (queryOptions: QueryOptions) => Promise<QueryResult>;
  showSearch?: boolean
}


export default function Grid({columns, fetchData, onRowClick, cacheName, showSearch}: GridProps) {
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [], linkOperator: GridLinkOperator.And
  });

  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);

  const columsWithFilters = columns;

  const mapPageToNextCursor = React.useRef<{[page: number]: string | undefined}>({0: undefined});

  const { data, isLoading } = useQuery([cacheName, page, pageSize, filterModel, sortModel], (query) => {
    const page = query.queryKey[1] as number;
    const pageSize = query.queryKey[2] as number;
    const cursor = mapPageToNextCursor.current[page - 1];

    return fetchData({page, pageSize, cursor, filterModel, sortModel}).then((data) => {
      mapPageToNextCursor.current[page] = data.pageInfo.nextCursor
      return data
    });
  })

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGridPro
        components={{ Toolbar: GridToolbar }}
        rows={data?.data || []}
        columns={columsWithFilters}
        rowCount={data?.pageInfo.totalRowCount || 0}
        loading={isLoading}
        rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, 5000, 10000, 100000]}
        pageSize={pageSize}
        page={page}
        paginationMode="server"
        sortingMode="server"
        onPageChange={(newPage => setPage(newPage))}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        componentsProps={{
          filterPanel: {
            linkOperators: [GridLinkOperator.And],
          },
          toolbar: {
              showQuickFilter: showSearch,
              quickFilterProps: {
                debounceMs: 500,
              },
            },
        }}
        filterMode={"server"}
        onFilterModelChange={(model) => {
          setPage(0);
          mapPageToNextCursor.current = {0: undefined}
          setFilterModel(model);
        }}
        onSortModelChange={(model) => {
          setPage(0);
          mapPageToNextCursor.current = {0: undefined}
          setSortModel(model);
        }}
        onRowClick={(event) => onRowClick(event.id)}
      />
    </Box>
  );
}
