import * as React from 'react';
import { Client } from '../domain/client';
import { GetAllUsersEvent, GetAllUsersResult } from 'bloggers-backend/lib/users/domain';
import LinkInput from './LinkInput';
import { OutlinedTextFieldProps } from '@mui/material';

interface User {
  id: string;
  name: string;
}


interface LinkInputProps extends OutlinedTextFieldProps {
  onChoose: (user: User | null) => any;
  client: Client
  value: User | null
}

async function getAllUsers(client: Client) {
  const result = await client<GetAllUsersEvent, GetAllUsersResult>({
    event: 'getAllUsers',
    data: {}
  });

  if (result.success) {
    return result.value.data
  }

  throw new Error("getAllUser failed");
}

export function UserLinkInput({client, onChoose, value, ...props}: LinkInputProps) {
  return <LinkInput<User>
    onChoose={onChoose}
    value={value}
    getOptions={() => getAllUsers(client)}
    {...props}
  />
}
