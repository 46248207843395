import { GetAllBloggerResult, GetAllBloggersEvent } from 'bloggers-backend/lib/bloggers/domain';
import { Button } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import Grid, { QueryOptions } from './Grid';
import { AppContext } from '../AppContext';
import { convertUtcToLocalTime } from '../Utils';

function takeHost(number: number) {
  return ({ row }: any) => row?.hosts?.[number]
}

const columns = [
  {
    field: 'name',
    width: 150,
    filterable: true,
    sortable: true,
    renderCell: ({row}: any) => (<Link to={`/bloggers/${row?.id}`}>{row?.name}</Link>)
  },
  { field: 'manager.name', headerName: 'manager', width: 150, filterable: true, sortable: true, valueGetter: ({ row }: any) => row?.manager?.name},
  { field: 'channelTheme', headerName: 'channel theme', width: 150, filterable: true, sortable: true },
  { field: 'status', width: 150, filterable: true, sortable: true },
  {
      field: 'email',
      width: 150,
      filterable: true,
      sortable: true,
      renderCell: ({row}: any) => (<a href={`https://mail.google.com/mail/?view=cm&source=mailto&to=${row?.email}`}>{row?.email}</a>)
  },
  { field: 'instagram', width: 150, filterable: true, sortable: false },
  { field: 'country', width: 150, filterable: true, sortable: true },
  { 
    field: 'createTime', 
    headerName: 'create time', 
    width: 160, 
    filterable: true, 
    sortable: true,
    valueGetter: ({ row }: any) => convertUtcToLocalTime(row?.createTime)
  },
  {
      field: 'lastComment.text',
      headerName: 'last comment',
      width: 150,
      filterable: false,
      sortable: false,
      valueGetter: ({ row }: any) => row?.lastComment?.text
  },
  { 
    field: 'lastComment.date',
    headerName: 'last comment date',
    width: 160,
    filterable: true,
    sortable: true,
    valueGetter: ({ row }: any) => convertUtcToLocalTime(row?.lastComment?.date)
  },
  { field: 'host1', headerName: 'host 1', width: 150, filterable: false, sortable: false, valueGetter: takeHost(0) },
  { field: 'host2', headerName: 'host 2', width: 150, filterable: false, sortable: false, valueGetter: takeHost(1) },
  { field: 'host3', headerName: 'host 3', width: 150, filterable: false, sortable: false, valueGetter: takeHost(2) },
  { field: 'youtube', width: 150, filterable: true, sortable: false, hide: true },
  { field: 'youtubeChannelId', width: 150, filterable: true, sortable: false, hide: true },
  { field: 'tikTok', width: 150, filterable: true, sortable: false, hide: true },
];


export default function BloggersPage() {
  const {client, notificationsService} = React.useContext(AppContext);
  const navigate = useNavigate();

  const fetchData = async (options: QueryOptions) => {
    const result = await client<GetAllBloggersEvent, GetAllBloggerResult>({
      event: 'getAllBloggers',
      data: options as any
    })

    if (result.success) {
      return result.value
    } else {
      notificationsService.notify({
        severity: 'error',
        message: `Error: ${JSON.stringify(result.errors)}`
      });

      return {data: [], pageInfo: {}}
    }
  };


  return <Box sx={{display: 'flex', flexDirection: 'column', height: '100%' }}>
    <Box sx={{marginBottom: 1}}>
      <Button
        variant="contained"
        onClick={() => navigate('/bloggers/new')}
      >
        Create new blogger
      </Button>
    </Box>
    <Box sx={{flexGrow: 1}}>
      <Grid
        cacheName={'bloggers'}
        columns={columns}
        fetchData={fetchData}
        onRowClick={(id) => {}}
        showSearch={true}
      />
    </Box>
  </Box>
}
