import * as React from 'react';
import {
  BrowserRouter as Router, Navigate,
} from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Box from '@mui/material/Box';
import { Login } from '../pages/Login';
import { ChangePassword } from '../pages/ChangePassword';
import { ResetPassword } from '../pages/ResetPassword';

export const LoggedOutView = () => {
  return (
    <React.StrictMode>
      <Router>
          <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Routes>
              <Route path="/login" element={<Login/>}></Route>
              <Route path="/change-password/:token" element={<ChangePassword/>}></Route>
              <Route path="/reset-password" element={<ResetPassword/>}></Route>
              <Route path="*" element={<Navigate to="/login" />}></Route>
            </Routes>
          </Box>
      </Router>
    </React.StrictMode>
  );
}
