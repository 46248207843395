import { ImportPublicRelationsEvent, ImportPublicRelationsResult } from "bloggers-backend/lib/pr/domain";

import { Box } from '@mui/material';
import * as React from 'react';
import { AppContext } from '../AppContext';

export default function PublicRelationsImportPage() { 
  const {client} = React.useContext(AppContext);

  const [message, setMessage] = React.useState('')
  const [loading, setLoading] = React.useState(false);

  async function upload(file: File) {
      setLoading(true)

      const text = await file.text()

      const result = await client<ImportPublicRelationsEvent, ImportPublicRelationsResult>({
          event: 'importPublicRelations',
          data: {csv: text}
      });

      if (result.success) {
          setMessage('success')
      } else {
          setMessage('Error\n' + JSON.stringify(result, null, 4))
      }

      setLoading(false);
  }

  return <Box>
      <pre>{message}</pre>

      <p>{loading ? "LOADING" : ""}</p>

      <input type="file"
          onChange={
          (event) => {
              const file = event.target.files?.[0]

              if (file && file.type === "text/csv") {
                  setMessage('')
                  upload(file)
              } else {
                  setMessage('should be a CSV file')
              }
          }
      }/>
  </Box>
}
