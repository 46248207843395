import * as React from "react";
import { Form as FormikForm} from 'formik';
import { Wrapper } from "./Wrapper";

interface Props {
  children: React.ReactNode;
}

export const Form = ({children}: Props) => {
  return (
    <FormikForm>
      <Wrapper>
        {children}
      </Wrapper>
    </FormikForm>
  );
};
