import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { Autocomplete, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import {
    CreateSearchTaskEvent,
    CreateSearchTaskResult,
    GetSearchTaskEvent,
    GetSearchTaskResult,
    DeleteSearchTaskEvent,
    DeleteSearchTaskEventResult,
    UpdateSearchTaskEvent,
    UpdateSearchTaskEventResult
} from 'bloggers-backend/lib/youtube/domain/events';
import { Channel, SearchTask } from 'bloggers-backend/lib/youtube/domain/SearchTask';
import { DataGrid } from '@mui/x-data-grid';
import { CreateBloggerEvent, CreateBloggerResult } from 'bloggers-backend/lib/bloggers/domain';
import { DeleteDiaglog } from '../components/DeleteDialog';
import { countries } from 'bloggers-backend/lib/bloggers/dicts/countries';
import { AppContext } from '../AppContext';
import { convertUtcToLocalTime } from '../Utils';


function getChannelUrl(id: string) {
    return `https://youtube.com/channel/${id}`;
}

export default function SearchTaskPage() {
    const {notificationsService, client} = React.useContext(AppContext);

  let { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  // Pass the useFormik() hook initial form values and a submit function that will
  // be called when the form is submitted

  const initialValues: SearchTask = {
    id: '',
    query: '',
    channels: [] as Channel[],
    error: '',
    status: 'new',
    createdAt: '',
    maxResults: 50,
    manager: {
        id: "", 
        name: "",
    },
    completed: false,
    countries: [],
    excludedCountries: [],
    strategy: "video",
    order: 'relevance',
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
        setLoading(true);
        if (id) {
            const result = await client<UpdateSearchTaskEvent, UpdateSearchTaskEventResult>({
                event: 'updateSearchTask',
                data: values
            })

            if (result.success) {
                notificationsService.notify({
                    severity: 'success',
                    message: 'SearchTask updated'
                });
            } else {
                notificationsService.notify({
                    severity: 'error',
                    message: `Error: ${JSON.stringify(error)}`
                });
            }
        } else {
            const result = await client<CreateSearchTaskEvent, CreateSearchTaskResult>({
                event: 'createSearchTask',
                data: values
            })

            if (result.success) {
                notificationsService.notify({
                    severity: 'success',
                    message: 'SearchTask created'
                });
                navigate(`/search-tasks/${result.value}`);
            } else {
                notificationsService.notify({
                    severity: 'error',
                    message: `Error: ${JSON.stringify(result.errors)}`
                });
            }
        }
        setLoading(false);
    },
  });
    useEffect(() => {
        if (!id) {
            return;
        }

        (async () => {
            setLoading(true)
            const result = await client<GetSearchTaskEvent, GetSearchTaskResult>(
                {event: 'getSearchTask', data: {id}}
            )

            if (result.success) {
                formik.resetForm({values: result.value})
            } else {
                notificationsService.notify({
                    message: JSON.stringify(result.errors),
                    severity: "error",
                })
            }
            setLoading(false)
        })()
    }, [id]);

    if (error) {
        return (<Box>{error}</Box>);
    }

    const header = id ? 'Edit a search task' : 'Create a new search task'

    const buttonOrLoading = 
        loading
        ? <CircularProgress />
        : (<Button
            onClick={formik.submitForm}
            variant="contained"
            type="submit">Submit
        </Button>)

    async function deleteSearchTask() {
        setDeleteDialogOpen(false);
        setLoading(true);
    
        if (!id) {
          throw new Error('Can not delete blogger with no id');
        }
        const result = await client<DeleteSearchTaskEvent, DeleteSearchTaskEventResult>({
          event: 'deleteSearchTask',
          data: {id}
        });

        if (result.success) {
          notificationsService.notify({
              severity: 'success',
              message: 'Search deleted'
          });
          navigate(`/search-tasks/`);
        } else {
          setLoading(false);
          notificationsService.notify({
              severity: 'error',
              message: JSON.stringify(result.errors)
          });
        }
    }

    async function createBlogger(channel: Channel) {

        channel.isBloggerCreated = true;
        await formik.handleSubmit();
        
        const result = await client<CreateBloggerEvent, CreateBloggerResult>({
        event: 'createBlogger',
        data: {
            name: channel.name,
            youtube: getChannelUrl(channel.id),
            youtubeChannelId: channel.id,
            country: channel.country,
            instagram: channel.instagram ?? null,
            tikTok: channel.tikTok ?? null,
            facebook: channel.facebook ?? null,
            email: null,
            addEmail: null
        }
        });
    
        if (result.success) {
        notificationsService.notify({
            severity: 'success',
            message: 'Blogger created'
        });
        } else {
        if (result.reason === 'validation') {
            notificationsService.notify({
                severity: 'error',
                message: JSON.stringify(result.errors)
            });
        } else {
            notificationsService.notify({
            severity: 'error',
            message: result.reason
            })
        }
        }
    }
    
  const strategies = ['video', 'channel']; 
  const orders =['date', 'rating', 'viewCount', 'relevance', 'title', 'videoCount'];

  return (
    <Box sx={{
        height: '100%',
        width: '100%',
        display: "flex",
        flexDirection: "row"
        }}>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            '& > :not(style)': { m: 1 },
            width: '25%',
            height: '60%'
        }}>
            <h2>{header} ({formik.values.status})</h2>
            <TextField
                id="query"
                name="query"
                label="query"
                variant="outlined"
                required
                disabled={id !== undefined}
                onChange={formik.handleChange}
                value={formik.values.query}
            />
            <Autocomplete
                id="strategy"
                disabled={id !== undefined}
                onChange={(_, value) => formik.setFieldValue('strategy', value)}
                options={strategies}
                value={formik.values.strategy}
                renderInput={(params) =>
                <TextField {...params}
                    error={!!formik.errors.strategy}
                    helperText={formik.errors.strategy || ""}
                    id="strategy"
                    label="strategy"
                />}
            />
            <Autocomplete
                id="order"
                disabled={id !== undefined}
                onChange={(_, value) => formik.setFieldValue('order', value)}
                options={orders}
                value={formik.values.order}
                renderInput={(params) =>
                <TextField {...params}
                    error={!!formik.errors.order}
                    helperText={formik.errors.order || ""}
                    id="order"
                    label="order"
                />}
            />
            <TextField
                id="maxResults"
                name="maxResults"
                label="max results"
                variant="outlined"
                required
                disabled={id !== undefined}
                onChange={formik.handleChange}
                value={formik.values.maxResults}
            />
            <Autocomplete
                multiple
                id="countries"
                options={countries}
                disabled={id !== undefined}
                value={formik.values.countries}
                onChange={(_, value) => formik.setFieldValue('countries', value)}
                renderInput={(params) => (
                <TextField variant="outlined" {...params} label="countries" placeholder="countries" />
                )}
            />
            <Autocomplete
                multiple
                id="excludedCountries"
                options={countries}
                disabled={id !== undefined}
                value={(formik.values.excludedCountries) || []}
                onChange={(_, value) => formik.setFieldValue('excludedCountries', value)}
                renderInput={(params) => (
                <TextField variant="outlined" {...params} label="excluded countries" placeholder="excluded countries" />
                )}
            />
            {
                id !== undefined
                ? (
                    <>
                        <TextField
                            id="createdBy"
                            name="createdAt"
                            label="created at"
                            variant="outlined"
                            required
                            disabled={true}
                            value={convertUtcToLocalTime(formik.values.createdAt)}
                        />
                        <TextField
                            id="manager"
                            name="manager"
                            label="manager"
                            variant="outlined"
                            disabled={true}
                            value={formik.values.manager?.name ?? ""}
                        />
                        <FormControlLabel
                            label="Completed"
                            control={
                            <Checkbox
                                checked={formik.values.completed}
                                value={formik.values.completed}
                                onChange={(event) => {
                                    formik.setFieldValue('completed', event.target.checked)
                                }}
                            />
                        }
                        />
                    </>
                )
                : <></>
            }
            <pre>
                {formik.values.error}
            </pre>
            {buttonOrLoading}

            <DeleteDiaglog
                isOpen={deleteDialogOpen}
                deleteWhat={"search task"}
                userRejects={() => setDeleteDialogOpen(false)}
                userAccepts={deleteSearchTask}
            />
            <Button
                variant="contained"
                color="error"
                onClick={() => setDeleteDialogOpen(true)}
                >Delete
            </Button>
        </Box>
        <Box sx={{ height: '90%', width: '75%', marginTop: '3.15%'}}>
            <DataGrid
                    rows={formik.values.channels}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'name',
                            width: 320,
                            renderCell: ({row}) => (<a href={`https://youtube.com/channel/${row?.id}`}>{row?.name}</a>)
                        },
                        {
                            field: 'country',
                            headerName: 'country',
                            width: 200,
                        },
                        {
                            field: 'controls',
                            headerName: 'controls',
                            width: 160,
                            renderCell: ({row}) => (
                                <Button
                                    onClick={() => {
                                        createBlogger(row)
                                    }}
                                    variant="contained"
                                    disabled={row?.isBloggerCreated}
                                >
                                    Add blogger
                                </Button> 
                            )
                        },
                        {
                            field: 'videoDate',
                            headerName: 'last video',
                            width: 200,
                            renderCell: ({row}: any) => convertUtcToLocalTime(row?.videoDate),
                        },
                        {
                            field: 'viewCount',
                            headerName: 'view count',
                            width: 160,
                            type: 'number',
                        },
                        {
                            field: 'subscriberCount',
                            headerName: 'subscriber count',
                            width: 160,
                            type: 'number',
                        },
                        {
                            field: 'instagram',
                            headerName: 'Instagram',
                            width: 160,
                            type: 'string',
                        },
                        {
                            field: 'facebook',
                            headerName: 'Facebook',
                            width: 160,
                            type: 'string',
                        },
                        {
                            field: 'tikTok',
                            headerName: 'TikTok',
                            width: 160,
                            type: 'string',
                        },
                    ]}
                    rowsPerPageOptions={[10, 100, 1000]}
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                />
        </Box>
    </Box>
  )
};