import * as React from 'react';
import {default as MuiAppBar} from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from './AppContext';

interface AppBarProps {
  pages: Page[]
}

interface Page {
  label: string;
  route: string
}


export default function AppBar ({pages}: AppBarProps) {
  const {signOut} = React.useContext(AppContext);

  const location = useLocation();
  const navigate = useNavigate();
  
  return <MuiAppBar position='static'>
    <Toolbar>
      <Box sx={{ display: { xs: 'flex', md: 'flex' }, flexGrow: 1, justifyContent: 'space-between'}}>
        <Box sx={{ display: { xs: 'flex', md: 'flex' }}}>
          {pages.map((page) => (
            <Button 
              key={page.label}
              sx={{
                my: 2, 
                color: 'white',
                border: `1px solid ${location.pathname === page.route ? 'white' : 'primary'}`,
                display: 'block' 
              }}
              onClick={() => {
                navigate(page.route)
              }}
            >
              {page.label.toUpperCase()}
            </Button>
          ))}
        </Box>

        <Box>
          <Button
            sx={{ my: 2, color: 'white', display: 'block' }}
            onClick={() => signOut()}
          >
            Sign out
          </Button>
        </Box>
      </Box>
    </Toolbar>
  </MuiAppBar>
}
