import { Button } from '@mui/material';
import {useState} from 'react';
import { EmailProps, SendEmail } from './email/SendEmail';

export const NewEmailDialog = (props: {mail: EmailProps}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShowModal(true)}
        variant="contained">
          New email
      </Button>
      {showModal && <SendEmail mail={props.mail} onClose={() => setShowModal(false)}/>}
    </>
  )
}
