import {
  NotificationSubscriber,
  Notification,
  NotificationService,
} from "../domain/Notifications";

export default class SimpleNotificationService implements NotificationService {
  private subscribers: NotificationSubscriber[] = [];

  public subscribe(fn: NotificationSubscriber) {
    this.subscribers.push(fn);
  }

  public notify(notification: Notification) {
    this.subscribers.forEach((fn) => {
      fn(notification);
    });
  }
}
