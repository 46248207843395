import * as React from 'react';
import { Button, DialogActions, DialogTitle, Dialog } from '@mui/material';

interface Props {
    isOpen: boolean
    deleteWhat: string
    userRejects: () => void
    userAccepts: () => void
}

export const DeleteDiaglog = ({userRejects, userAccepts, deleteWhat, isOpen}: Props) => (
    <Dialog
    open={isOpen}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
        <DialogTitle>Do you want to delete a {deleteWhat}?</DialogTitle>
        <DialogActions>
        <Button autoFocus onClick={userRejects}>
        No
        </Button>
        <Button onClick={() => userAccepts()} autoFocus>
        Yes
        </Button>
        </DialogActions>
    </Dialog>
)
