import { useContext, useEffect } from 'react';
import { GetGoogleToken } from '../components/GenerateGoogleToken';
import { Wrapper } from '../components/forms/Wrapper';
import { AppContext } from '../AppContext';
import { useLocation } from 'react-router-dom';

export default function CreateEmailAccountPage() {
    const header = 'Create a new account'
    const {notificationsService} = useContext(AppContext);
    const location = useLocation();
    const error = new URLSearchParams(location.search).get('error');

    useEffect(() => {
        if (error) {
          notificationsService.notify({
            severity: "error",
            message: error
          })
        }
    }, [error]);

    return (
        <Wrapper>
            <h2>{header}</h2>
            <GetGoogleToken/>
        </Wrapper>
    )
};