import * as React from 'react';
import { Button, DialogActions, DialogTitle, Dialog, DialogContent } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

interface Props {
    isOpen: boolean
    userRejects: () => void
    userAccepts: (date: string) => void
}

export const PickDateDialog = ({userRejects, userAccepts, isOpen}: Props) => {
    const [isoDate, setIsoDate] = React.useState('');

    return (
         <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>Please pick a date</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        onChange={(date: Date | null) => {
                            if (date) {
                                const localTime = date.toLocaleString();
                                setIsoDate(date.toISOString());
                            }
                        }}
                    />
                </LocalizationProvider>
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={userRejects}>
                    No
                </Button>
                <Button
                    disabled={isoDate === ""}
                    onClick={() => userAccepts(isoDate)} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}
